import React, { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ControllerInput from "./ControllerInput";
import moment from "moment";
import TimeWindowController from "./TimeWindowController";
import SuggestionInput from "@src/orders/create/components/SuggestionInput";
import { ShipmentStopType } from "@api/graphql/generated/generated-types";
import { DatePicker, Label, Textarea } from "@src/common/components";
import StopDateSelector from "@src/orders/create/components/CreateStopsForm/components/StopDateSelector";
import { findIfStopHasFutureDateDelivery } from "@src/common/lib/shipmentStateFinders";

function StopInputs({
  stopIndex = 0,
  selectedCustomer,
  stopPathName = "stops",
  isRecurring = false,
  selectedDuration,
  onStopDateChange,
}) {
  const { control, setValue, clearErrors, getValues, watch } = useFormContext();
  const initialRender = useRef(true);
  const watchCloseTime = watch(
    `${stopPathName}[${stopIndex}].timeWindow.${
      isRecurring ? "closeSecondsSinceMidnightUtc" : "close"
    }`
  );
  const watchOpenTime = watch(
    `${stopPathName}[${stopIndex}].timeWindow.${
      isRecurring ? "openSecondsSinceMidnightUtc" : "open"
    }`
  );

  useEffect(() => {
    if (!selectedDuration) {
      return;
    }
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (selectedDuration === "noStopDates") {
        setValue(`${stopPathName}[${stopIndex}].stopDate`, null);
        setValue(`${stopPathName}[${stopIndex}].deliverByDate`, null);
      }
    }
  }, [selectedDuration]);

  const formatTime = (time: number) => {
    if (!time) {
      return;
    }
    if (isRecurring) {
      const exactMoment = moment.unix(time).utc();
      return moment(exactMoment).local().format("HH:mm");
    }
    let formattedTime = moment(Number(time * 1000)).format("HH:mm");
    return formattedTime;
  };
  const stopType: ShipmentStopType = getValues(
    `${stopPathName}[${stopIndex}].type`
  );

  const hasFutureDateDelivery = findIfStopHasFutureDateDelivery(
    getValues(`${stopPathName}[${stopIndex}]`)
  );

  const shouldShowDeliverByDate =
    (stopType === ShipmentStopType.DropOff ||
      stopType === ShipmentStopType.Return) &&
    (hasFutureDateDelivery || selectedDuration === "hasStopDates");

  return (
    <>
      <div className="col-span-1 flex">
        <ControllerInput
          name={`${stopPathName}[${stopIndex}].suite`}
          label={"Suite"}
          className={"flex-1"}
        />

        {selectedDuration === "hasStopDates" && (
          <div className={"flex-1 ml-2"}>
            <StopDateSelector
              name={`${stopPathName}[${stopIndex}].stopDate`}
              stopType={stopType}
              control={control}
              selectedDuration={selectedDuration}
              onStopDateChange={onStopDateChange}
              index={stopIndex}
            />
          </div>
        )}
      </div>

      <div
        className="col-span-1 flex"
        key={watch(`${stopPathName}[${stopIndex}].timeWindow`)}
      >
        {stopType === ShipmentStopType.PickUp && (
          <TimeWindowController
            stopIndex={stopIndex}
            label="Ready"
            name={`${stopPathName}[${stopIndex}].timeWindow.${
              isRecurring ? "openSecondsSinceMidnightUtc" : "open"
            }`}
            control={control}
            formatTime={formatTime}
            watchValue={watchOpenTime}
            className="flex-1"
          />
        )}
        {stopType === ShipmentStopType.DropOff && (
          <TimeWindowController
            stopIndex={stopIndex}
            label="Deliver By"
            name={`${stopPathName}[${stopIndex}].timeWindow.${
              isRecurring ? "closeSecondsSinceMidnightUtc" : "close"
            }`}
            control={control}
            formatTime={formatTime}
            watchValue={watchCloseTime}
            className={"flex-1"}
          />
        )}
        {shouldShowDeliverByDate && (
          <div className="flex-1 ml-2 mt-4">
            <Controller
              control={control}
              name={`${stopPathName}[${stopIndex}].deliverByDate`}
              render={({ field }) => (
                <DatePicker
                  size="mini"
                  value={watch(`${stopPathName}[${stopIndex}].deliverByDate`)}
                  formatString={"MMM do, yyyy"}
                  onChange={({ date }) => field.onChange(date)}
                  autoFocusCalendar={false}
                />
              )}
            />
          </div>
        )}
      </div>

      <div className={"col-span-2"}>
        <SuggestionInput
          control={control}
          type={"contactName"}
          selectedCustomer={selectedCustomer}
          setValue={setValue}
          clearErrors={clearErrors}
          shipmentStopsFormName={`${stopPathName}[${stopIndex}]`}
          isStopFullUpdate={true}
          usePositive={false}
        />
      </div>

      <div className={"col-span-1"}>
        <SuggestionInput
          control={control}
          type={"companyName"}
          selectedCustomer={selectedCustomer}
          setValue={setValue}
          clearErrors={clearErrors}
          shipmentStopsFormName={`${stopPathName}[${stopIndex}]`}
          isStopFullUpdate={true}
          usePositive={false}
        />
      </div>

      <div className={"col-span-1"}>
        <SuggestionInput
          control={control}
          type={"phone"}
          selectedCustomer={selectedCustomer}
          setValue={setValue}
          clearErrors={clearErrors}
          shipmentStopsFormName={`${stopPathName}[${stopIndex}]`}
          isStopFullUpdate={true}
          usePositive={false}
        />
      </div>

      <div className="col-span-4">
        <Label className="block text-xs font-medium text-gray-700 truncate">
          Instruction
        </Label>
        <Controller
          name={`${stopPathName}[${stopIndex}].instruction`}
          control={control}
          render={({ field }) => (
            <Textarea
              size="mini"
              placeholder=""
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      </div>

      {stopType === ShipmentStopType.Return && (
        <TimeWindowController
          stopIndex={stopIndex}
          label="Deliver By"
          name={`${stopPathName}[${stopIndex}].timeWindow.${
            isRecurring ? "closeSecondsSinceMidnightUtc" : "close"
          }`}
          control={control}
          formatTime={formatTime}
          watchValue={watch(
            `${stopPathName}[${stopIndex}].timeWindow.${
              isRecurring ? "closeSecondsSinceMidnightUtc" : "close"
            }`
          )}
        />
      )}
    </>
  );
}

export default StopInputs;
